<template lang="pug">
  .uk-margin.details
    p.uk-text-small
      span(v-if="lineItem.amount")
        | {{ lineItem.amount }}x 
      span {{ lineItem.name }}
      selected-variants(
        v-if="lineItem.variants"
        :variants="lineItem.variants"
      )

    .uk-text-small(v-if="plainPrice") {{ price }}
    vk-grid.uk-margin-small-top(v-else)
      div.uk-width-expand
        line-item-control(
          v-if="!readOnly"
          :line-item="lineItem"
          :date-key="dateKey"
        )
      .price {{ price }}
</template>

<script>
import LineItemControl from "./LineItemControl"
import SelectedVariants from "./SelectedVariants"
import { formatPrice } from "../helpers/functions"

export default {
  components: {
    LineItemControl,
    SelectedVariants
  },
  props: {
    dateKey: {
      type: String,
      required: true
    },
    lineItem: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    plainPrice: {
      type: Boolean,
      default: false
    },
    shouldFormatPrice: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    price() {
      if (this.shouldFormatPrice) {
        return formatPrice(this.lineItem.price)
      }
      return this.lineItem.price
    }
  }
}
</script>

<style lang="scss" scoped>
h4, p {
  margin: 0
}

.control-cell {
  min-width: 85px;
}

.price-cell {
  @media (max-width: 640px) {
    margin-top: -35px;
  }
}
</style>
