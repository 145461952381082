import Vue from "vue"
import Vuex from "vuex"

import { setAuthHeaderPlugin } from "../services/http"

import notification from "./notification"
import auth from "./auth"
import catalog from "./catalog"
import cart from "./cart"
import page from "./page"
import order from "./order"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    notification,
    auth,
    catalog,
    cart,
    page,
    order
  },
  plugins: [setAuthHeaderPlugin]
})
