<template lang="pug">
  div
    div(:class="{ 'uk-width-1-1': fullscreen, 'uk-inline': icon }")
      span.uk-form-icon(v-if="icon" :uk-icon="`icon: ${icon}`")
      label(
        v-if="fieldLabel"
        :for="id"
      ) {{ fieldLabel }}
      textarea.uk-textarea(
        v-if="multiline"
        :value="value"
        :id="id"
        :name="name"
        :placeholder="label"
        @input="input"
        @blur="input"
        ref="input"
        :disabled="disabled"
        :rows="rows"
      )
      input.uk-input(
        v-else
        :id="id"
        :type="type"
        :name="name"
        :server-errors="serverErrors"
        :placeholder="label"
        :value="value"
        @input="input"
        @blur="input"
        ref="input"
        :disabled="disabled"
      )
    validation-errors(:label="label" :validations="validations")
    .uk-text-danger.uk-text-small(
      v-for="(error, index) in serverErrors"
      :key="index"
    )
      | {{ error }}
    .uk-text-small(v-if="hint") {{ hint }}
</template>

<script>
import ValidationErrors from "./ValidationErrors"
import { uuid } from "../helpers/functions"

export default {
  components: { ValidationErrors },
  props: {
    multiline: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    name: {
      type: String,
      required: true
    },
    serverErrors: {
      type: Array,
      default: () => []
    },
    fieldLabel: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    validations: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: null
    },
    icon: {
      type: String,
      default: null
    }
  },
  emits: ["input"],
  data() {
    return {
      id: uuid()
    }
  },
  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => this.$refs.input.focus())
    }
  },
  methods: {
    input() {
      if (this.validations) {
        this.validations.$touch()
      }
      if (this.$refs.input) {
        this.$emit("input", this.$refs.input.value)
      }
    }
  }
}
</script>
