export const OUTPUT_DATE_FORMAT = "dddd l"
export const DEFAULT_ORDER_LEAD_TIME_IN_DAYS = 2
export const ACCOUNT_INFO_PERMALINK = "account-info"
export const MIN_HOURS_FOR_NEXT_DAY_ORDER = 12

export const WEEKDAYS = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag"
]
