<template lang="pug">
  .small-cart-overview(:uk-grid="shouldUseGrid")
    template(v-for="dateKey in Object.keys(dateGroups)")
      .group(:class="groupClassFor(dateKey)")
        h4.uk-margin-remove {{ dateFor(dateKey) }}
          .uk-inline(v-if="isDayCancelable(dateKey)")
            a(uk-icon="triangle-down" href="#" @click.prevent)
            vk-drop(mode="click" position="right-center")
              vk-card
                submit-button.uk-width-1-1(
                  :label="t('pages.checkout.cart.cancelation.cancel_order')"
                  :is-submitting="isCanceling"
                  @click.native.prevent.stop="$emit('cancelOrder', dateKey)"
                )

        line-item-small(
          v-for="lineItem in dateGroups[dateKey]"
          :key="`${lineItem.productId}-${dateKey}-${lineItem.variantsKey}`"
          :date-key="dateKey"
          :line-item="lineItem"
          :read-only="readOnly"
          :plain-price="plainPrice"
        )
        line-item-small(
          v-if="discountLineItemFor(dateKey)"
          :date-key="dateKey"
          :line-item="discountLineItemFor(dateKey)"
          :read-only="true"
          :plain-price="plainPrice"
        )
    .group(
      v-if="discountLineItem || creditLineItem"
      :class="groupClass"
    )
      h4.uk-margin-remove {{ t("pages.checkout.cart.used_discounts") }}

      line-item-small(
        v-if="discountLineItem"
        date-key=""
        :line-item="discountLineItem"
        :read-only="true"
        :plain-price="plainPrice"
      )

      line-item-small(
        v-if="creditLineItem"
        date-key=""
        :line-item="creditLineItem"
        :read-only="true"
        :plain-price="plainPrice"
      )

      //- line-item-small(
      //-   v-if="spendPointHistories.length"
      //-   v-for="pointHistory in spendPointHistories"
      //-   :key="pointHistory.id"
      //-   date-key=""
      //-   :line-item="pointHistoryLineItemFor(pointHistory)"
      //-   :read-only="true"
      //-   :plain-price="plainPrice"
      //-   :should-format-price="pointHistory.action === 'spend'"
      //- )

    .group(
      v-if="earnedPointHistories.length || formattedOrderDiscount"
    )
      h4.uk-margin-remove
        u {{ t("pages.checkout.cart.credit_information") }}
      .uk-margin(style="margin: 5px 0px !important;")
        p.uk-text-small(style="margin: 0" v-if="earnedPointHistories.length")
          | {{ t("pages.checkout.cart.order_points_earned") }}
        p.uk-text-small(style="margin: 0" v-if="formattedOrderDiscount")
          | {{ t("pages.checkout.cart.order_cancel_amount", { amount: formattedOrderDiscount }) }}
</template>

<script>
import LineItemSmall from "../components/LineItemSmall"
import SubmitButton from "../components/SubmitButton"
import { groupArray, dateFor } from "../helpers/functions"
import { mapGetters } from "vuex"

function filteredPointHistories(pointHistories, action) {
  if (!pointHistories || !pointHistories.length) {
      return []
    }

    return pointHistories.filter(pointHistory => pointHistory.action === action)
}

export default {
  components: {
    LineItemSmall,
    SubmitButton
  },
  props: {
    lineItems: {
      type: Array,
      required: true
    },
    discounts: {
      type: Array,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    shouldUseGrid: {
      type: Boolean,
      default: false
    },
    groupClass: {
      type: String,
      default: "uk-section-xsmall"
    },
    plainPrice: {
      type: Boolean,
      default: false
    },
    pointHistories: {
      type: Array,
      default: () => []
    },
    isCancelable: {
      type: Boolean,
      default: false
    },
    isCanceling: {
      type: Boolean,
      default: false
    },
    formattedOrderDiscount: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters("catalog", ["isOrderableOn"]),

    dateGroups() {
      return groupArray(this.lineItems, ["dateKey"])
    },
    colspan() {
      return this.readOnly ? 3 : 4
    },
    discountLineItem() {
      const discount = this.discounts.find(d => d.isDiscount && !d.isUserDiscount)
      if (!discount) {
        return null
      }
      return {
        name: discount.code || discount.name,
        price: discount.value
      }
    },
    creditLineItem() {
      let amount = 0
      this.discounts.forEach(discount => {
        if (discount.isUserDiscount) {
          amount += discount.value
        }
      })
      if (amount === 0) {
        return null
      }
      return {
        name: "Guthaben",
        price: amount
      }
    },
    earnedPointHistories() {
      return filteredPointHistories(this.pointHistories, "earn")
    }
  },
  methods: {
    discountLineItemFor(dateKey) {
      const discount = this.discounts.find(d => d.dateKey === dateKey && !d.isDiscount)
      if (!discount) {
        return
      }
      return {
        name: discount.name,
        price: discount.value,
        canceledAt: discount.canceledAt,
        amount: 1
      }
    },
    pointHistoryLineItemFor(pointHistory) {
      return {
        name: this.t(`pages.checkout.cart.points.action.${pointHistory.action}`, { points: pointHistory.amount }),
        price: pointHistory.action === "spend" ? -pointHistory.totalAmountPrice : pointHistory.amount,
        amount: null
      }
    },
    dateFor(dateKey) {
      return dateFor(dateKey)
    },
    isDayCanceled(dateKey) {
      return Boolean(this.dateGroups[dateKey] && this.dateGroups[dateKey].find(li => li.canceledAt))
    },
    isDayCancelable(dateKey) {
      const date = dateFor(dateKey, false)
      if (date < new Date()) {
        return false
      }
      return this.isCancelable && this.isOrderableOn(date) && !this.isDayCanceled(dateKey)
    },
    groupClassFor(dateKey) {
      let classNames = [this.groupClass || ""]

      window.aaa = this
      if (this.isDayCanceled(dateKey)) {
        classNames.push("canceled")
      }

      return classNames.join(" ")
    }
  }
}
</script>

<style lang="scss" scoped>
.group {
  border-bottom: 1px #f4f4f4 solid;

  &:last-child {
    border-bottom: 0;
  }
}

.canceled {
  text-decoration: line-through;
}
</style>
