<template lang="pug">
  ul
    li(v-for="link in links")
      router-link(
        :to="link.to"
        @click.native="$emit('didSelectNavigationLink')"
      ) {{ link.title }}
</template>

<script>
import { WEEKDAYS, MIN_HOURS_FOR_NEXT_DAY_ORDER } from "../config/constants"
import moment from "moment"

function nextWeekday() {
  const date = moment()
  switch (date.weekday()) {
    case 5:
    case 6:
      return WEEKDAYS[0]
    case 0:
    case 1:
      return WEEKDAYS[1]
  }
  if (date.hours(MIN_HOURS_FOR_NEXT_DAY_ORDER) > new Date()) {
    return WEEKDAYS[date.weekday() + 1] || WEEKDAYS[0]
  }
  return WEEKDAYS[date.weekday() + 2] || WEEKDAYS[0]
}

export default {
  props: {
    reduced: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      WEEKDAYS
    }
  },
  computed: {
    links() {
      let links = [{ to: { name: "Home" }, title: "Ihre Kantine" }]

      if (this.reduced) {
        const weekday = nextWeekday()
        links.push({
          to: {
            name: "Catalog",
            params: {
              weekday: weekday.toLowerCase()
            },
          },
          title: "Bestellen"
        })
      } else {
        WEEKDAYS.forEach(weekday => {
          links.push({ to: { name: "Catalog", params: { weekday: weekday.toLowerCase() } }, title: weekday })
        })
      }
      return links
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  letter-spacing: 2px;
}

.uk-navbar-nav > li > a {
  @media (max-width: 768px) {
    padding: 0 8px;
  }
}
</style>
