import ContentService from "../services/contentService"

export default {
  namespaced: true,
  state: {
    isLoading: false,
    contents: {},
    headerImageUrl: null
  },
  getters: {
    contentFor: state => permalink => {
      return state.contents[permalink]
    }
  },
  mutations: {
    setHeaderImageUrl(state, imageUrl) {
      state.headerImageUrl = imageUrl
    },
    setContent(state, { permalink, content }) {
      state.contents = {
        ...state.contents,
        [permalink]: content
      }
    }
  },
  actions: {
    setHeaderImageUrl({ commit }, imageUrl) {
      commit("setHeaderImageUrl", imageUrl)
    },
    async loadContent({ state, commit }, permalink) {
      commit("setContent", { permalink, content: null })
      state.isLoading = true
      try {
        const content = await ContentService.get(permalink)
        commit("setContent", { permalink, content })
      } catch (error) {
        console.log("content error: ", error)
      } finally {
        state.isLoading = false
      }
    }
  }
}
