import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"

const ContentPage = () => import("../pages/ContentPage")
const Catalog = () => import("../pages/Catalog")
const Cart = () => import("../pages/Cart")
const SignUp = () => import("../pages/SignUp")
const SignIn = () => import("../pages/SignIn")
const ResendConfirmation = () => import("./../pages/ResendConfirmation")
const PasswordReset = () => import("./../pages/PasswordReset")
const PasswordChange = () => import("./../pages/PasswordChange")
const MyAccount = () => import("./../pages/MyAccount")
const OrderDetails = () => import("./../pages/OrderDetails")
const Checkout = () => import("./../pages/Checkout")
const CheckoutAddress = () => import("./../pages/CheckoutAddress")
const CheckoutPayment = () => import("./../pages/CheckoutPayment")
const CheckoutConfirmation = () => import("./../pages/CheckoutConfirmation")
const OrderCompleted = () => import("./../pages/OrderCompleted")
const OrderFailed = () => import("./../pages/OrderFailed")

Vue.use(VueRouter)

export const routes = [
  {
    path: "/",
    name: "Home",
    component: ContentPage
  },
  {
    path: "/kantine/:weekday",
    name: "Catalog",
    component: Catalog,
    props: true,
    meta: { authenticated: true }
  },
  {
    path: "/seiten/:permalink",
    name: "Page",
    component: ContentPage,
    props: true
  },
  {
    path: "/bestellen",
    name: "Checkout",
    component: Checkout,
    meta: { authenticated: true, checkout: true },
    redirect: { name: "Cart" },
    children: [
      {
        path: "warenkorb",
        name: "Cart",
        component: Cart,
        meta: { key: "cart" }
      },
      {
        path: "addresse",
        name: "Address",
        component: CheckoutAddress,
        meta: { key: "address" }
      },
      {
        path: "bezahlung",
        name: "Payment",
        component: CheckoutPayment,
        meta: { key: "payment" }
      },
      {
        path: "bestaetigung",
        name: "Confirmation",
        component: CheckoutConfirmation,
        meta: { key: "confirmation" }
      }
    ]
  },
  {
    path: "/bestellungen/abgeschlossen",
    name: "OrderCompleted",
    component: OrderCompleted
  },
  {
    path: "/bestellungen/fehlerhaft",
    name: "OrderFailed",
    component: OrderFailed
  },
  {
    path: "/registrierung/:slug?",
    name: "SignUp",
    component: SignUp,
    props: true
  },
  {
    path: "/login",
    name: "SignIn",
    component: SignIn
  },
  {
    path: "/bestaetigung",
    name: "ResendConfirmation",
    component: ResendConfirmation
  },
  {
    path: "/passwort/neu",
    name: "ResetPassword",
    component: PasswordReset
  },
  {
    path: "/passwort/bearbeiten",
    component: PasswordChange,
    name: "EditPassword",
    props: route => ({ resetPasswordToken: route.query.token })
  },
  {
    path: "/mein-konto",
    name: "MyAccount",
    component: MyAccount,
    meta: { authenticated: true }
  },
  {
    path: "/mein-konto/bestellungen/:id",
    name: "OrderDetails",
    component: OrderDetails,
    meta: { authenticated: true },
    props: true
  },
  {
    path: "*",
    component: ContentPage,
    props: route => ({ permalink: "not_found" })
  }
]

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  if (!store.state.auth.restored) {
    await store.dispatch("restoreLocalSession")
    if (store.state.auth.user) {
      await store.dispatch("cart/loadCart")
    }
  }

  let accessAllowed = true

  to.matched.forEach(record => {
    if (!record.meta.authenticated) {
      return
    }

    if (!store.state.auth.user) {
      accessAllowed = false
      return
    }

    const { role } = store.state.auth.user
    const { authenticated } = record.meta

    if (typeof authenticated === "string") {
      accessAllowed = role === authenticated
    } else if (Array.isArray(authenticated)) {
      accessAllowed = authenticated.includes(role)
    } else {
      // route's `meta.authenticated` can be set to just `true` in order
      //   to allow any authenticated user access the page
      accessAllowed = !!authenticated
    }
  })

  if (to && to.matched && to.matched.length && to.matched[0].meta && to.matched[0].meta.checkout && to.meta && to.meta.key) {
    if (!store.getters["cart/visitableStates"].includes(to.meta.key)) {
      return next({
        name: "Cart"
      })
    }
  }

  if (!accessAllowed) {
    return next({
      name: "SignIn",
      params: { nextUrl: to.fullPath }
    })
  }

  next()
})

export default router
