<template lang="pug">
  .uk-container
    .loader(v-if="isLoading")
      .spinner(uk-spinner="ratio: 3")
    slot(v-if="!isLoading")
</template>

<script>
import { mapActions } from "vuex"

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    headerImageUrl: {
      type: String,
      default: null
    }
  },
  watch: {
    headerImageUrl(newValue) {
      this.setHeaderImageUrl(newValue)
    }
  },
  created() {
    this.setHeaderImageUrl(this.headerImageUrl)
  },
  methods: {
    ...mapActions("page", ["setHeaderImageUrl"])
  }
}
</script>

<style lang="scss" scoped>
.uk-container {
  min-height: 90px;

  .spinner {
    margin: auto;
    width: 90px;
    display: block;
  }
}
</style>
