<template lang="pug">
  .form-validation(v-if="validations && validations.$dirty")
    .uk-text-danger(v-if="hasError('required')")
      | {{ t("errors.messages.required") }}

    .uk-text-danger(v-if="hasError('minLength')")
      | {{ t("errors.messages.too_short", { count: validations.$params.minLength.min }) }}

    .uk-text-danger(v-if="hasError('maxLength')")
      | {{ t("errors.messages.too_long", { count: validations.$params.maxLength.max }) }}

    .uk-text-danger(v-if="hasError('email')")
      | {{ t("errors.messages.email") }}

    .uk-text-danger(v-if="hasError('mustBeAccepted')")
      | {{ t("errors.messages.accepted") }}

    .uk-text-danger(v-if="hasError('valueContainedInList')")
      | {{ t("errors.messages.inclusion") }}

    .uk-text-danger(v-if="hasError('taken')")
      | {{ t("errors.messages.taken") }}

    .uk-text-danger(v-if="hasError('sameAsPassword')")
      | {{ t("errors.messages.confirmation", { attribute: passwordHumanAttributeName }) }}

    .uk-text-danger(v-if="hasError('sameAsEmail')")
      | {{ t("errors.messages.confirmation", { attribute: emailHumanAttributeName }) }}

    .uk-text-danger(v-if="hasError('hasLowerAndUpperCase')")
      | {{ t("errors.messages.too_few_char_cases") }}

    .uk-text-danger(v-if="hasError('hasNumberOrSpecial')")
      | {{ t("errors.messages.no_number_or_special_char") }}
</template>

<script>
export default {
  props: {
    validations: {
      type: Object,
      required: true
    }
  },
  computed: {
    passwordHumanAttributeName() {
      const attr = this.t("activerecord.attributes.user.password")
      return `${attr[0].toUpperCase()}${attr.slice(1)}`
    },
    emailHumanAttributeName() {
      const attr = this.t("activerecord.attributes.user.email")
      return `${attr[0].toUpperCase()}${attr.slice(1)}`
    }
  },
  methods: {
    hasError(type) {
      return this.validations && this.validations.hasOwnProperty(type) && !this.validations[type]
    }
  }
}
</script>
<style lang="scss" scoped>
.form-validation {
  padding: 0.5rem;
  font-size: 12px;

  &:empty {
    padding: 0;
  }
}
</style>
