import Vue from "vue"

import CartService from "../services/cartService"
import UserService from "../services/userService"
import moment from "moment"
import { formatPrice } from "../helpers/functions"

function allUserPointEuroAmount(state) {
  return specialDiscountAmount(state, true) * -1
}

function discountAmount(state) {
  if (!state.discounts.length) {
    return 0
  }
  return state.discounts
    .map(discount => discount.value)
    .reduce((accumulator, currentValue) => accumulator + currentValue)
}

function specialDiscountAmount(state, isUserCode) {
  if (!state.discountCodes || !state.discountCodes.length) {
    return 0
  }
  const total = lineItemAmount(state) + discountAmount(state)
  if (total <= 0) {
    return 0
  }

  let sum = 0
  state.discountCodes
    .filter(discountCode => discountCode.isUserCode === isUserCode)
    .forEach(discount => {
      let value = 0;
      switch (discount.discountType) {
        case "percent":
          value = Math.round(total / 100 * discount.amount * 100) / 100
          break
        case "fix":
          value = discount.amount
      }

      // return Math.max(value, 0) * -1
      sum += Math.max(value, 0) * -1
    })
  return Math.max(sum, total * -1)
}

function lineItemAmount(state) {
  if (!state.lineItems.length) {
    return 0
  }
  return state.lineItems
    .map(lineItem => lineItem.amount * lineItem.price)
    .reduce((accumulator, currentValue) => accumulator + currentValue)
}

function subAmount(state) {
  const total = lineItemAmount(state)
  if (total === 0) {
    return 0
  }
  const discount = discountAmount(state) + specialDiscountAmount(state, false)

  return Math.max(total + discount, 0)
}

function amount(state) {
  const total = subAmount(state)
  if (!state.usePointsAsCurrency || pointsDiscountAmount(state) >= 0) {
    return total
  }
  return total + pointsDiscountAmount(state)
}

function pointsDiscountAmount(state) {
  if (allUserPointEuroAmount(state) <= 0) {
    return 0
  }

  const total = subAmount(state)
  return Math.min(total, allUserPointEuroAmount(state)) * -1
}

function findLineItemIndex(lineItems, productId, dateKey, variantsKey) {
  return lineItems.findIndex(itm => {
    if (itm.productId !== productId || itm.dateKey !== dateKey) {
      return false
    }
    return !variantsKey || itm.variantsKey === variantsKey
  })
}

async function loadCart(state, commit) {
  state.isLoading = true
  try {
    const cart = await CartService.list()
    commit("setLineItems", cart.lineItems)
    commit("setNotes", cart.notes)
    commit("setAddress", cart.address)
    commit("setPaymentMethod", cart.paymentMethod)
    commit("setDiscountCodes", cart.discountCodes)
    commit("setDiscounts", cart.discounts)
  } catch (error) {
    commit("setLineItems", [])
    commit("setNotes", null)
    commit("setAddress", {})
    commit("setPaymentMethod", null)
    commit("setDiscountCodes", [])
    commit("setDiscounts", [])
  }
  state.isLoading = false
}

export default {
  namespaced: true,
  state: {
    isLoading: false,
    lineItems: [],
    discounts: [],
    discountCodes: [],
    notes: null,
    address: {},
    paymentMethod: null,
    isCartVisible: false,
    isSavingCart: false,
    usePointsAsCurrency: false
  },
  getters: {
    isCartEmpty(state) {
      return Boolean(!state.lineItems || !state.lineItems.length)
    },
    amount(state) {
      return amount(state)
    },
    formattedAmount(state) {
      return formatPrice(amount(state))
    },
    formattedVat(state) {
      const vat = amount(state) - (amount(state) / 1.07)
      return formatPrice(vat)
    },
    itemAmount(state) {
      if (!state.lineItems.length) {
        return 0
      }
      return state.lineItems
        .map(lineItem => lineItem.amount)
        .reduce((accumulator, currentValue) => accumulator + currentValue)
    },
    formattedSubTotal(state) {
      return formatPrice(Math.max(lineItemAmount(state) + discountAmount(state), 0))
    },
    formattedLineItemAmount(state) {
      return formatPrice(lineItemAmount(state))
    },
    formattedOrganizationDiscountAmount(state) {
      return formatPrice(discountAmount(state))
    },
    formattedSpecialDiscountAmount(state) {
      return formatPrice(specialDiscountAmount(state, false))
    },
    allUserPointEuroAmount(state) {
      return allUserPointEuroAmount(state)
    },
    hasSpecialDiscounts(state) {
      return specialDiscountAmount(state, false) !== 0
    },
    visitableStates(state) {
      let states = ["cart"]
      if (!state.lineItems.length) {
        return states
      }
      if (state.lineItems.length) {
        states.push("address")
      }
      if (state.address && Object.keys(state.address).length) {
        states.push("payment")
      }
      if (state.paymentMethod) {
        states.push("confirmation")
      }
      return states
    },
    formattedPointsCurrency(state) {
      return formatPrice(pointsDiscountAmount(state))
    },
    discount(state) {
      if (!state.discountCodes || !state.discountCodes.length) {
        return null
      }
      return state.discountCodes.find(discount => !discount.isUserCode)
    }
  },
  mutations: {
    add(state, { product, dateKey, variants }) {
      const variantsKey = variants ? Object.keys(variants).join("-")+"-"+Object.values(variants).join("-") : null
      const index = findLineItemIndex(state.lineItems, product.id, dateKey, variantsKey)
      if (index >= 0) {
        let lineItem = state.lineItems[index]
        lineItem.amount += 1
        Vue.set(state.lineItems, index, lineItem)
        return
      }
      state.lineItems.push({
        productId: product.id,
        price: product.price,
        name: product.name,
        amount: 1,
        dateKey,
        variants,
        variantsKey
      })
    },
    remove(state, { product, dateKey, variantsKey }) {
      const index = findLineItemIndex(state.lineItems, product.id, dateKey, variantsKey)
      if (index < 0) {
        return
      }
      let lineItem = state.lineItems[index]
      if (lineItem.amount > 1) {
        lineItem.amount -= 1
        Vue.set(state.lineItems, index, lineItem)
        return
      }
      state.lineItems.splice(index, 1)
    },
    removeAll(state, { product, dateKey, variantsKey }) {
      const index = findLineItemIndex(state.lineItems, product.id, dateKey, variantsKey)
      if (index < 0) {
        return
      }
      state.lineItems.splice(index, 1)
    },
    setLineItems(state, lineItems) {
      state.lineItems = lineItems
    },
    setDiscounts(state, discounts) {
      state.discounts = discounts
    },
    setNotes(state, notes) {
      state.notes = notes
    },
    setAddress(state, address) {
      state.address = address
    },
    setPaymentMethod(state, paymentMethod) {
      state.paymentMethod = paymentMethod
    },
    setIsCartVisible(state, isCartVisible) {
      state.isCartVisible = isCartVisible
    },
    setDiscountCodes(state, discountCodes) {
      state.discountCodes = discountCodes
    },
    setUsePointsAsCurrency(state, usePoints) {
      state.usePointsAsCurrency = usePoints
    }
  },
  actions: {
    async addProduct({ commit, state }, { product, dateKey, shouldShowCart, variants }) {
      state.isSavingCart = true
      try {
        commit("add", { product, dateKey, variants })
        if (shouldShowCart) {
          commit("setIsCartVisible", true)
        }
        const cart = await CartService.add(product, dateKey, variants)
        commit("setLineItems", cart.lineItems)
        commit("setDiscounts", cart.discounts)
      } catch (error) {
        console.log("Error while adding product", error)
      }
      state.isSavingCart = false
    },
    async removeProduct({ commit, state }, { product, dateKey, variantsKey }) {
      state.isSavingCart = true
      try {
        commit("remove", { product, dateKey, variantsKey })
        const cart = await CartService.remove(product, dateKey, variantsKey)
        commit("setLineItems", cart.lineItems)
        commit("setDiscounts", cart.discounts)
      } catch (error) {
        console.log("Error while adding product", error)
      }
      state.isSavingCart = false
    },
    async removeAllProducts({ commit, state }, { product, dateKey, variantsKey }) {
      state.isSavingCart = true
      try {
        commit("removeAll", { product, dateKey, variantsKey })
        const cart = await CartService.removeAll(product, dateKey, variantsKey)
        commit("setLineItems", cart.lineItems)
        commit("setDiscounts", cart.discounts)
      } catch (error) {
        console.log("Error while adding product", error)
      }
      state.isSavingCart = false
    },
    async setNotes({ commit }, notes) {
      const data = await CartService.setNotes(notes)
      commit("setNotes", data.notes)
    },
    async setAddress({ commit }, form) {
      const cart = await CartService.setAddress(form)
      commit("setAddress", cart.address)
    },
    async setPaymentMethod({ commit }, paymentMethod) {
      const cart = await CartService.setPaymentMethod(paymentMethod)
      commit("setPaymentMethod", cart.paymentMethod)
    },
    setUsePointsAsCurrency({ commit }, shouldUsePoints) {
      commit("setUsePointsAsCurrency", shouldUsePoints)
    },
    async convertUserPointEuroAmount({ commit, rootState }) {
      try {
        await UserService.convertPoints()
        await loadCart(state, commit)

        rootState.auth.user.points = 0
        return true
      } catch (error) {
        return false
      }
    },
    async placeOrder({ commit }, data) {
      const orderResult = await CartService.placeOrder(data)
      return orderResult
    },
    async loadCart({ state, commit }) {
      loadCart(state, commit)
    },
    setIsCartVisible({ commit }, isCartVisible) {
      commit("setIsCartVisible", isCartVisible)
    },
    async addDiscountCode({ commit, dispatch, state }, code) {
      try {
        const cart = await CartService.addDiscountCode(code)
        commit("setDiscountCodes", cart.discountCodes)
        return true
      } catch (error) {
        commit("setDiscountCodes", state.discountCodes.filter(d => d.isUserCode))
        const message = error.response.data.message
        dispatch("notify", { message }, { root: true })
        return false
      }
    }
  }
}
