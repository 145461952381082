import http from "./http"
const PATH = "/api/orders"

export default {
  async index() {
    const { data: result } = await http.get(PATH)
    return (result && result.orders) || []
  },

  async cancelOrder({ id, dateKey }) {
    await http.delete(`${PATH}/${id}?date_key=${dateKey}`)
  }
}
