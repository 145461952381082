<template lang="pug">
  footer
    .uk-container
      vk-grid.uk-padding.uk-padding-remove-horizontal.uk-text-small
        div(
          v-for="col in cols"
          :class="`uk-width-1-${cols.length}@m`"
        )
          h2 {{ col.title }}
          vue-markdown {{ col.content }}
</template>

<script>
import VueMarkdown from "vue-markdown"

export default {
  components: {
    VueMarkdown
  },
  data() {
    return {
      cols: [
        {
          title: "Kontakt",
          content: "Feinkost Lautenschläger GmbH\nLouisenstraße 50\n61348 Bad Homburg vor der Höhe\n\n#### T 06172 / 60059 - 59\nE info@feinkost-lautenschlaeger.de\n\n[Fragen & Antworten](/seiten/faq)"
        },
        {
          title: "Öffnungszeiten",
          content: "Montag – Freitag:        9 – 14 Uhr"
        },
        {
          title: "Impressum",
          // content: "**Feinkost Lautenschläger GmbH**\nLouisenstrasse 50\n61348 Bad Homburg v.d.H.\n\nT: 0 61 72 / 6 00 59-0\ninfo@feinkost-lautenschlaeger.de\n\n\n**Geschäftsführer**\nMarcus Meye, Thomas Meye\n\nHRB 2634 Amtgericht Bad Homburg\nUST. IDENT. NR. DE 114 22 99 20\n\n**AGB´s**\n[AGB´s zum Download](https://cdn.shopify.com/s/files/1/1239/7564/files/AGBs_Feinkost_Lautenschlaeger_GmbH.pdf?13986089718413770922)\n\n**Datenschutzerklärung**\n[Datenschutzerklärung zum Download](/datenschutzbestimmung.pdf)\n\n**Inhaltlich verantwortlich:**\nFeinkost Lautenschläger GmbH"
          content: "**Feinkost Lautenschläger GmbH**\nLouisenstrasse 50\n61348 Bad Homburg v.d.H.\n\nT: 0 61 72 / 6 00 59-0\ninfo@feinkost-lautenschlaeger.de\n\n\n**Geschäftsführer**\nMarcus Meye, Thomas Meye\n\nHRB 2634 Amtgericht Bad Homburg\nUST. IDENT. NR. DE 114 22 99 20\n\n**Datenschutzerklärung**\n[Datenschutzerklärung zum Download](/datenschutzbestimmung.pdf)\n\n**Inhaltlich verantwortlich:**\nFeinkost Lautenschläger GmbH"
        }
      ]
    }
  },
  mounted() {
    this.$el.querySelectorAll("a").forEach(a => {
      if (!a.href || !a.getAttribute("href").startsWith("/seiten/")) {
        return
      }
      a.addEventListener("click", event => {
        event.preventDefault()
        event.stopPropagation()
        const permalink = event.target.getAttribute("href").split("/")[2]
        this.$router.push({ name: "Page", params: { permalink }})
      })
    })
  }
}
</script>

<style lang="scss" scoped>
footer {
  background: #333;
  color: white;

  h2 {
    color: white;
  }

  /deep/ {
    a {
      color: white;
      text-decoration: underline;
    }

    h4 {
      color: white;
      margin: 3px 0 -12px;
    }
  }
}
</style>
