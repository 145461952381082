import http from "../services/http"
import { DEFAULT_ORDER_LEAD_TIME_IN_DAYS } from "../config/constants"
import Settings from "../config/settings.js"

const retrieveToken = tokenName =>
  localStorage.getItem(tokenName) || sessionStorage.getItem(tokenName)

const removeToken = tokenName =>
  localStorage.removeItem(tokenName) || sessionStorage.removeItem(tokenName)

export default {
  namespaced: false,
  state: {
    user: null,
    token: null,
    restored: false
  },
  getters: {
    isUserLoggedIn(state) {
      return state.user !== null
    },
    currentUser(state) {
      return state.user
    },
    orderLeadTime(state) {
      const user = state.user
      return (user && user.organization && user.organization && user.organization.orderLeadTimeInDays) || DEFAULT_ORDER_LEAD_TIME_IN_DAYS
    },
    organization(state) {
      return state.user && state.user.organization
    },
    pointsInEuroAmount(state) {
      const points = state.user.points || 0
      if (points < Settings.minimumAmountToSpend) {
        return 0
      }

      return Math.round(Settings.eurosPerPoint * points * 100) / 100
    }
  },
  mutations: {
    setUser(state, { user, token }) {
      state.user = user
      state.token = token
    }
  },
  actions: {
    async restoreLocalSession({ commit, state, dispatch }) {
      const token = retrieveToken("auth_token")

      if (token) {
        try {
          const { data: user } = await http.get("/users/current", {
            headers: { Authorization: token }
          })
          commit("setUser", { user, token })
          state.restored = true
        } catch (error) {
          removeToken("auth_token")
        }
      }
    },
    async login({ commit }, { email, password, remember }) {
      const {
        data: user,
        headers: { authorization: token }
      } = await http.post("/users/sign_in", {
        user: {
          email,
          password
        }
      })

      commit("setUser", {
        user,
        token
      })

      if (remember) {
        localStorage.setItem("auth_token", token)
      } else {
        sessionStorage.setItem("auth_token", token)
      }
    },
    async signup(_, form) {
      let result = {}

      try {
        await http.post("/users", {
          user: form
        })
        result.error = false
      } catch (error) {
        const { data } = error.response
        result.message = data.errors || error.message
        result.error = true
      }
      return result
    },
    async resetPassword(context, email) {
      await http.post("/users/password", { user: { email } })
    },
    async resendConfirmation(context, email) {
      await http.post("/users/confirmation", { user: { email } })
    },
    async updatePassword(context, payload) {
      await http.patch("/users/password", { user: payload })
    },
    async completeProfile(context, form) {
      try {
        await http.patch(`/api/users/current.json`, form)
      } catch (error) {
        const { data } = error.response
        const message = data.errors || error.message
        notify(message, "danger")
      }
    },
    async updateUser({ commit }, form) {
      const token = retrieveToken("auth_token")
      const { data: user } = await http.patch(`/api/users/current/addresses/current.json`, form)
      commit("setUser", { user, token })
    },
    async logout({ commit }) {
      await http.delete("/users/sign_out")

      removeToken("auth_token")
      commit("setUser", {
        user: null,
        token: null
      })
    }
  }
}
