import http from "./http"
const PATH = "/api/carts"
const LINE_ITEMS_PATH = "/api/carts/current/line_items"
const ADDRESS_PATH = "/api/carts/current/addresses"
const PAYMENT_PATH = "/api/carts/current/payment_methods"
const DISCOUNT_PATH = "/api/carts/current/discounts"
const ORDER_PATH = "/api/orders"

export default {
  async list() {
    const { data: lineItems } = await http.get(PATH)
    return lineItems
  },
  async add(product, dateKey, variants) {
    const data = {
      id: product.id,
      dateKey,
      variants
    }
    const { data: cart } = await http.post(LINE_ITEMS_PATH, data)
    return cart
  },
  async remove(product, dateKey, variantsKey) {
    const { data: cart } = await http.delete(`${LINE_ITEMS_PATH}/${product.id}?date_key=${dateKey}&variants_key=${variantsKey || ""}`)
    return cart
  },
  async removeAll(product, dateKey, variantsKey) {
    const { data: cart } = await http.delete(`${LINE_ITEMS_PATH}/${product.id}?date_key=${dateKey}&variants_key=${variantsKey || ""}&remove_all=true`)
    return cart
  },
  async setNotes(notes) {
    const data = {
      notes
    }
    const { data: cart } = await http.post(PATH, data)
    return cart
  },
  async setAddress(address) {
    const data = {
      address
    }
    const { data: cart } = await http.post(ADDRESS_PATH, data)
    return cart
  },
  async setPaymentMethod(paymentMethod) {
    const data = {
      paymentMethod
    }
    const { data: cart } = await http.post(PAYMENT_PATH, data)
    return cart
  },
  async placeOrder({ useUserDiscounts }) {
    const { data: orderResult } = await http.post(ORDER_PATH, { useUserDiscounts })
    return orderResult
  },
  async addDiscountCode(code) {
    const { data: result } = await http.post(DISCOUNT_PATH, { code })
    return result
  }
}
