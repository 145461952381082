<template lang="pug">
  #navigation.uk-sticky.uk-active.uk-sticky-fixed(uk-sticky="media: 960")
    .header.uk-position-relative
      .uk-position-center
          router-link(:to="{ name: 'Home' }")
            img(src="../images/logo.jpg")
      
      .buttons.uk-flex.uk-flex-right
        my-account-button
        cart-button

    nav.uk-navbar-container.uk-navbar
      .uk-navbar-left(class="uk-hidden@s")
        //- navigation-links.uk-navbar-nav.uk-navbar-nav(:reduced="true")
        vk-offcanvas-content.uk-position-absolute
          ul.uk-navbar-nav
            li
              a.uk-navbar-toggle(
                href="#"
                uk-navbar-toggle-icon
                @click.prevent.stop="isNavbarVisible = !isNavbarVisible"
              )

        vk-offcanvas(overlay :show.sync="isNavbarVisible")
          .uk-offcanvas-bar
            navigation-links.uk-nav(@didSelectNavigationLink="isNavbarVisible = !isNavbarVisible")

      .uk-navbar-center
        navigation-links.uk-navbar-nav(class="uk-navbar-nav uk-visible@s")
</template>

<script>
import NavigationLinks from "./NavigationLinks"
import MyAccountButton from "./MyAccountButton"
import CartButton from "./CartButton"

export default {
  components: {
    NavigationLinks,
    MyAccountButton,
    CartButton
  },
  data() {
    return {
      isNavbarVisible: false
    }
  },
  computed: {
    showCardButton() {
      if (!this.$route.matched) {
        return true
      }
      return !Boolean(this.$route.matched.find(route => route.meta && route.meta.checkout))
    }
  }
}
</script>

<style lang="scss" scoped>
#navigation {
  background: white;
}

.header {
  height: 70px;

  img {
    width: 160px;
  }

  .buttons {
    position: absolute;
    height: 70px;
    right: 10px;

    & > div {
      padding: 10px 5px;

      /deep/ a {
        color: #666;
      }
    }

    @media (max-width: 640px) {
      top: 70px;
      z-index: 999;

      & > div {
        padding: 2px 5px;

        /deep/ a {
          color: #fff;
        }
      }
    }
  }
}

.uk-navbar-container:not(.uk-navbar-transparent) {
  background: #666;
}

.uk-navbar-container, .uk-navbar-center {
  height: 50px;
}

.uk-navbar-center:not(:only-child), .uk-navbar-nav {
  z-index: 100;
}

/deep/ .uk-navbar-nav > li > a {
  color: #999;
  min-height: 50px;

  &.router-link-exact-active.router-link-active {
    color: white;
  }
}

/deep/ .uk-navbar-nav > li:hover > a,
/deep/ .uk-navbar-nav > li > a:focus,
/deep/ .uk-navbar-nav > li > a.uk-open {
  color: #999;
}
</style>
